
// @ is an alias to /src
import { onMounted, onUnmounted, reactive, watch } from 'vue'
import axios from "@/plugin/vue-axios";
import { useStore } from "vuex";

export default {
    name: 'ReumCalendar',
    components: {
    },
    props: {
        modelValue: {
            type: Object,
        },
        month: {
            type: Number,
            default: -1,
        },
        year: {
            type: Number,
            default: -1,
        },
    },
    emits: [ 'update:modelValue', 'change' ],
    setup(props, context) {
        const store = useStore();
        const calendar = reactive({
            info : {
                cell : null,
                startday : 0,
                today : null,
                strToday : "",
                week : 0,
            },
            dates: [],
            cellinfo : [],
            addDate : (itm) => {
                if(itm=='') {
                    return;
                }
                let pos = calendar.dates.indexOf(itm);
                let action = "";
                if(pos>=0) {
                    action = "del";
                    calendar.dates.splice(pos,1);
                } else {
                    action = "add";
                    calendar.dates.push(itm);
                }
                let params = {
                    date: itm,
                    action: action,
                    id_member: store.state.id_member,
                }
                axios.post("/api/common/setHoliday", params);

                calendar.dates.sort();
                context.emit('update:modelValue', calendar.dates);
            },
            formatDate : (date) => {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();

                if (month.length < 2) 
                    month = '0' + month;
                if (day.length < 2) 
                    day = '0' + day;

                return [year, month, day].join('-');
            },
            makeCalendar : (date) => {
                calendar.cellinfo = [];
                let calendarYear = date.getFullYear();
                let calendarMonth = date.getMonth() + 1;
                calendar.info.today = date;
                calendar.info.strToday = calendar.formatDate(new Date());
                let monthLastDate = new Date(calendarYear, calendarMonth, 0);
                let calendarMonthLastDate = monthLastDate.getDate();
                let monthStartDay = new Date(calendarYear, date.getMonth(), 1);
                let calendarMonthStartDay = monthStartDay.getDay(); // 월의 시작 요일
                calendar.info.week = Math.ceil((calendarMonthStartDay + calendarMonthLastDate) / 7);
                let idx = -1;
                let day = 0;
                for(let i=0;i<calendar.info.week;i++) {
                    for(let j=0;j<7;j++) {
                        idx++;
                        let week = idx % 7;
                        if(idx<calendarMonthStartDay || day>=calendarMonthLastDate) {
                            calendar.cellinfo.push({day:"", week:week, date:''})
                        } else {
                            day++;
                            calendar.cellinfo.push({day:day, week:week, date:calendar.formatDate(new Date(calendarYear, calendarMonth - 1, day)) })
                        }
                    }
                }
            },
            init: () => {
                let date = new Date(props.year, props.month -1, 1);
                calendar.makeCalendar(date);
            },
        });

        const comp = reactive({
            pagesize: 0,
            setPagesize: () => {
                context.emit('update:modelValue', comp.pagesize);
                context.emit('change', comp.pagesize);
            }
        });

        watch(() => props.month, (newVal, oldVal) => {
            if(newVal != oldVal) {
                let target_date = new Date(props.year, props.month -1, 1);
                calendar.makeCalendar(target_date);
            }
        })
        watch(() => props.year, (newVal, oldVal) => {
            if(newVal != oldVal) {
                let target_date = new Date(props.year, props.month -1, 1);
                calendar.makeCalendar(target_date);
            }
        })

        watch(() => props.modelValue, (newVal, oldVal) => {
            if(newVal != oldVal) {
                calendar.dates = props.modelValue;
            }
        })

        onMounted(() => {
            calendar.init();
        });

        onUnmounted(() => { 
            // UnMounted
        });
        return {comp, calendar};
    }

}
