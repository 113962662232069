
import { onMounted, reactive, watch } from 'vue'
import ReumCalendar from '@/components/ReumLib/UI/ReumCalendar'
import ReumHeader from '@/components/ReumLib/layout/ReumHeader.vue'
import { useStore } from "vuex";
import axios from "@/plugin/vue-axios";

export default {
    name: 'Calendar',
    props: {
        modelValue: {
            type: [Number, String],
            default: "00:00",
        },
    },
    components: {
        ReumCalendar,
        ReumHeader
    },
    emits: [ 'update:modelValue' ],
    setup(props) {
        const store = useStore();
        const calendar = reactive({
            info : {
                today : null,
                week : 0,
            },
            init: () => {
                let date = new Date();
                let calendarYear = date.getFullYear();
                let calendarMonth = date.getMonth() + 1;
                calendar.info.today = date.getDate();
                let monthLastDate = new Date(calendarYear, calendarMonth, 0);
                let calendarMonthLastDate = monthLastDate.getDate();
                let monthStartDay = new Date(calendarYear, date.getMonth(), 1);
                let calendarMonthStartDay = monthStartDay.getDay(); // 월의 시작 요일
                calendar.info.week = Math.ceil((calendarMonthStartDay + calendarMonthLastDate) / 7);
            },
        });

        const comp = reactive({
            year: 2022,
            month: 7,
            dates : [],
            getDates: () => {
                axios.post("/api/common/getHolidays").then((res) => {
                    if(res.data.err == 0) {
                        if(Array.isArray(res.data.holidays)) {
                            comp.dates = res.data.holidays;
                        }
                    }
                });
            }
        });

        onMounted(() => {
            // Mounted
            if(!store.getters.checkAuth) {
                location.href="/Login";
                return;
            }
            let today = new Date();
            comp.year = today.getFullYear();
            comp.month = today.getMonth() + 1;
            comp.getDates();
        });

        watch(() => props.modelValue, (nVal, oVal) => {
            if(nVal != oVal) {
                comp.change();
            }
        });
        return {comp};
    }
}
