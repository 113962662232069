
import { onMounted, reactive } from 'vue'
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
    name: 'Sample',
    props: {
        modelValue: {
            type: [Number, String],
            default: "00:00",
        },
    },
    emits: [ 'update:modelValue' ],
    setup(props, { emit }) {
        const store = useStore();
        const router = useRouter();
        const comp = reactive({
            auth : false,
            data : "",
            user : "",
            menu : 1,
            logout: () => {
                store.commit("setMenu", 1);
                router.push({"path":"/Login"});
            },
            setMenu: (num) => {
                comp.menu = num;
                if(num==1) {
                    router.push({"path":"/SampleCtrl/list"});
                }
                if(num==2) {
                    router.push({"path":"/DNAPrep/list"});
                }
                if(num==3) {
                    router.push({"path":"/LibraryPrep/list"});
                }
                if(num==4) {
                    router.push({"path":"/ExomeCapture/list"});
                }
                if(num==5) {
                    router.push({"path":"/Sequencing/list"});
                }
                store.commit("setMenu", num);
            },
            change: () => {
                emit('update:modelValue', comp.data);
            }
        });

        onMounted(() => {
            // Mounted
            comp.auth = store.getters.checkAuth;
            comp.menu = store.state.menu;
        });

        return {comp, store};
    }
}
